import jquery from 'jquery';
window.$ = window.jQuery = jquery;

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

import { TweenMax, TimelineMax } from "gsap/dist/gsap.min";
import "scrollmagic/scrollmagic/minified/plugins/animation.gsap.min";

import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin.min";
window.ScrollToPlugin = ScrollToPlugin;

import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/minified/plugins/jquery.ScrollMagic.min.js';

function check_webp_feature(callback) {
    let img = new Image();
    img.onload = function () {
        let result = (img.width > 0) && (img.height > 0);
        callback(result);
    };
    img.onerror = function () {
        callback(false);
    };
    img.src = "data:image/webp;base64,UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==";
}
check_webp_feature((success) => {
    if (!success) {
        $("a").each((index, element) => {
            $(element).attr("href", $(element).attr("href").toString().replace(".webp", ".png"))
        });
    }
});

(function($) {
    "use strict"; // Start of use strict

    /* scroll magic initialize */
    const controller = new ScrollMagic.Controller();

    /* scroll to function */
    controller.scrollTo(function (newpos) {
        gsap.to(window, {duration: 1, scrollTo: {y: newpos-50}, ease: Power1.easeOut});
    });

    /* resize on scroll down */
    /* hide scroll down message on scroll down */
    const timeline = new TimelineMax()
        .add(TweenMax.to('.navbar', 0.6666, {
            backgroundColor: '#222'
        }), 0);
    new ScrollMagic.Scene({
        offset: 50,
        triggerHook: 'onEnter'
    })
        .setTween(timeline)
        .addTo(controller);


    const exp = new TimelineMax()
        .add(TweenMax.from('.exp-wrapper .exp-line', 0.75, {
            height: 0
        }), 0);

    $(".exp-wrapper .exp-row").each(function (){
        exp.add(TweenMax.from($(this), 0.5, {
                opacity: 0
            }));
    });
    new ScrollMagic.Scene({
        triggerElement: ".exp-wrapper",
        triggerHook: '0.6666'
    })
        .setTween(exp)
        .addTo(controller);

    const edu = new TimelineMax()
        .add(TweenMax.from('.edu-wrapper .edu-line', 0.75, {
            height: 0
        }), 0);

    $(".edu-wrapper .edu-row").each(function (){
        edu.add(TweenMax.from($(this), 0.5, {
                opacity: 0
            }));
    });
    new ScrollMagic.Scene({
        triggerElement: ".edu-wrapper",
        triggerHook: '0.6666'
    })
        .setTween(edu)
        .addTo(controller);

    new ScrollMagic.Scene({
        triggerElement: $("#about"),
        triggerHook: 0.3333
    }).setTween(TweenMax.from($(".about-desc"), 1, {
        opacity: 0,
    })).addTo(controller);

    const skills = new TimelineMax();
    $(".skill").each(function() {
        skills.add(TweenMax.from(($(this)), 0.25, {
            opacity: 0,
            y: -40
        }));
    });
    new ScrollMagic.Scene({
        triggerElement: ".skills",
        triggerHook: '0.6666'
    })
        .setTween(skills)
        .addTo(controller);

    const projects = new TimelineMax();
    $(".project").each(function() {
        projects.add(TweenMax.from(($(this)), 0.25, {
            opacity: 0,
            y: 40
        }));
    });
    new ScrollMagic.Scene({
        triggerElement: ".project",
        triggerHook: '0.6666'
    })
        .setTween(projects)
        .addTo(controller);

    /* navbar */
    /* scroll to links */
    $(document).on("click", ".page-scroll", function (e) {
        const id = $(this).attr("href");
        if ($(id).length > 0) {
            e.preventDefault();
            controller.scrollTo(id);
        }
        $(this).addClass("active");
    });


    $('body').mousemove(function(e) {
        const amountMovedX = (e.pageX * 0.2 / 10);
        const amountMovedY = (e.pageY * 0.2 / 10);
        const greenbox = $('.jaryl-img .green-box');
        greenbox.css('left', (-amountMovedX-10) + 'px');
        greenbox.css('top', (-amountMovedY+40) + 'px');
    });
    

    // floating label headings for the contact form
    $("body").on("input propertychange", ".floating-label-form-group", function (e) {
        $(this).toggleClass("floating-label-form-group-with-value", !!$(e.target).val());
    }).on("focus", ".floating-label-form-group", function () {
        $(this).addClass("floating-label-form-group-with-focus");
    }).on("blur", ".floating-label-form-group", function () {
        $(this).removeClass("floating-label-form-group-with-focus");
    });

    /* contact form */
    $( "#contactForm" ).submit(function(event) {
        event.preventDefault();
        $("#btnSubmit").attr("disabled", true);

        let values = {};
        $.each($('#contactForm').serializeArray(), function(i, field) {
            values[field.name] = field.value;
        });

        let name = values.name;
        let email = values.email;
        let message = values.message;
        let captcha = values["cf-turnstile-response"];

        $.ajax({
            url: "./contact",
            type: "POST",
            data: {name: name, email: email, message: message, captcha: captcha},
            cache: false,
            success: function () {
                $("#btnSubmit").attr("disabled", 0);
                $('#success').html("<div class='alert alert-success'>");
                const alertSuccess = $('#success > .alert-success');
                alertSuccess.html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;").append("</button>");
                alertSuccess.append("<strong>Your message has been sent.</strong></div>");
                $('#contactForm').trigger("reset");
                turnstile.reset(window.turnstileId);
            },
            error: function (data) {
                $('#success').html("<div class='alert alert-danger'>");
                const alertDanger = $('#success > .alert-danger')
                alertDanger.html("<button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;").append("</button>");
                alertDanger.append("<strong>" + data.status + " (" + data.statusText + "). " + data.responseText + "</strong></div>");
                $('#contactForm').trigger("reset");
                turnstile.reset(window.turnstileId);
            }
        });
    });


    $(function () {
        let currentHash = ""
        $(document).scroll(function () {
            $('section').each(function () {
                const top = window.scrollY;
                const fourth = window.innerHeight / 4;
                const distance = top - $(this).offset().top;
                const hash = $(this).attr("id");
                if (distance < fourth && distance > -fourth && currentHash !== hash) {
                    currentHash = hash;
                    umami.track("scroll: " + hash);
                }
            });
        });
    });

    const modals = $('.modal')
    modals.on('shown.bs.modal', function (e) {
        umami.track("open project: " + e.currentTarget.id);
    })
    modals.on('hidden.bs.modal', function () {
        umami.track("close project: " + e.currentTarget.id);
    })
})(jQuery); // End of use strict
